// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-minimalism-src-pages-404-js": () => import("./../../../../gatsby-theme-minimalism/src/pages/404.js" /* webpackChunkName: "component---gatsby-theme-minimalism-src-pages-404-js" */),
  "component---gatsby-theme-minimalism-src-pages-about-js": () => import("./../../../../gatsby-theme-minimalism/src/pages/about.js" /* webpackChunkName: "component---gatsby-theme-minimalism-src-pages-about-js" */),
  "component---gatsby-theme-minimalism-src-pages-index-js": () => import("./../../../../gatsby-theme-minimalism/src/pages/index.js" /* webpackChunkName: "component---gatsby-theme-minimalism-src-pages-index-js" */),
  "component---gatsby-theme-minimalism-src-pages-notes-js": () => import("./../../../../gatsby-theme-minimalism/src/pages/notes.js" /* webpackChunkName: "component---gatsby-theme-minimalism-src-pages-notes-js" */),
  "component---gatsby-theme-minimalism-src-pages-projects-js": () => import("./../../../../gatsby-theme-minimalism/src/pages/projects.js" /* webpackChunkName: "component---gatsby-theme-minimalism-src-pages-projects-js" */),
  "component---gatsby-theme-minimalism-src-templates-post-js": () => import("./../../../../gatsby-theme-minimalism/src/templates/post.js" /* webpackChunkName: "component---gatsby-theme-minimalism-src-templates-post-js" */)
}

